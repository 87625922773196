export const AppPermissions = {
  VIEW_DASHBOARD: 100,
  CREATE_DASHBOARD: 101,
  EDIT_DASHBOARD: 102,
  DELETE_DASHBOARD: 103,

  VIEW_REGIONS: 200,
  CREATE_REGIONS: 201,
  EDIT_REGIONS: 202,
  DELETE_REGIONS: 203,

  VIEW_AREAS: 300,
  CREATE_AREAS: 301,
  EDIT_AREAS: 302,
  DELETE_AREAS: 303,

  VIEW_STORES: 400,
  CREATE_STORES: 401,
  EDIT_STORES: 402,
  DELETE_STORES: 403,

  VIEW_TAB_STORES_FLOATING: 500,

  VIEW_DETAIL_STORES: 600,

  VIEW_TAB_COLLABORATORS: 700,

  VIEW_TAB_VACANCIES: 800,

  VIEW_TAB_TEMPLATE_STORES: 900,
  CREATE_TAB_TEMPLATE_STORES: 901,
  EDIT_TAB_TEMPLATE_STORES: 902,
  DELETE_TAB_TEMPLATE_STORES: 903,

  ACTION_POSITION_FIXED: 1000,
  ACTION_POSITION_TEMPORARY: 1100,

  VIEW_COLLABORATORS: 1200,

  ACTION_SEND_INVITATION_COLLABORATOR: 1300,

  VIEW_DETAIL_COLLABORATORS: 1400,

  VIEW_TAB_PROFILE: 1500,

  VIEW_SECTION_INFORMATION_PERSONAL: 1600,
  EDIT_SECTION_INFORMATION_PERSONAL: 1602,

  VIEW_SECTION_INFORMATION_COLLABORATOR: 1700,
  EDIT_SECTION_INFORMATION_COLLABORATOR: 1702,

  /*  VIEW_SECTION_INFORMATION_SALARY: 1800,
  EDIT_SECTION_INFORMATION_SALARY: 1802,
 */

  VIEW_SECTION_INFORMATION_BANK: 1800,
  EDIT_SECTION_INFORMATION_BANK: 1802,

  VIEW_SECTION_INFORMATION_MEMBERSHIPS: 1900,
  EDIT_SECTION_INFORMATION_MEMBERSHIPS: 1902,

  VIEW_TAB_DOCUMENTS: 2000,
  CREATE_ACTION_UPLOAD_DOCUMENT: 2001,
  EDIT_ACTION_UPLOAD_DOCUMENT: 2002,
  DElETE_ACTION_UPLOAD_DOCUMENT: 2003,

  VIEW_TAB_NOVELTIES: 2100,

  ACTION_CREATE_OUTFLOW: 2200,

  VIEW_TAB_EGRESS: 2300,

  ACTION_APPROVE_REQUEST: 2400,
  ACTION_EDIT_EGRESS: 2402,
  ACTION_DELETE_EGRESS: 2403,

  ACTION_REJECT_REQUEST: 2500,

  ACTION_UPLOAD_DOCUMENT_EGRESS: 2600,
  CREATE_ACTION_UPLOAD_DOCUMENT_EGRESS: 2601,
  EDIT_ACTION_UPLOAD_DOCUMENT_EGRESS: 2602,
  DElETE_ACTION_UPLOAD_DOCUMENT_EGRESS: 2603,

  VIEW_TAB_COLLABORATORS_HISTORY: 2700,

  ACTION_CREATE_MOVEMENT: 2800,

  VIEW_ADMINISTRATOR: 2900,
  CREATE_ADMINISTRATOR: 2901,
  EDIT_ADMINISTRATOR: 2902,
  DELETE_ADMINISTRATOR: 2903,

  ACTION_SEND_INVITATION_ADMINISTRATOR: 3000,

  VIEW_APPROVALS: 3100,

  VIEW_VACANCIES: 3200,
  EDIT_VACANCIES: 3202,

  ACTION_CREATE_COLLABORATOR_NEW: 3300,

  ACTION_CREATE_COLLABORATOR_EXISTING: 3400,

  VIEW_NOVELTIES: 3500,

  VIEW_REPORTS: 3600,

  VIEW_TAB_REPORT_INCOME: 3700,

  VIEW_TAB_REPORT_EGRESS: 3800,

  VIEW_TAB_REPORT_RECRUITMENT: 3900,

  VIEW_TAB_REPORT_MOVEMENTS: 4000,

  VIEW_PAYROLL: 4100,

  VIEW_TAB_PAYROLL_INCOME: 4200,

  VIEW_TAB_PAYROLL_EGRESS: 4300,

  VIEW_TAB_PAYROLL_MOVEMENTS: 4400,

  VIEW_CERTIFICATES: 4500,

  VIEW_CODES: 4600,
  CREATE_CODES: 4601,

  VIEW_MANAGERS: 4700,

  VIEW_TAB_MANAGERS_INCOME: 4800,

  VIEW_TAB_MANAGERS_VACANCIES: 4900,

  VIEW_TAB_MANAGERS_TEMPLATE: 5000,
  CREATE_TAB_MANAGERS_TEMPLATE: 5001,
  EDIT_TAB_MANAGERS_TEMPLATE: 5002,
  DELETE_TAB_MANAGERS_TEMPLATE: 5003,

  VIEW_PERMISSIONS: 5100,
  EDIT_PERMISSIONS: 5102,

  VIEW_ACTIVITY_LOGS: 5200,

  VIEW_INVOICES: 5300,

  VIEW_INDICATORS: 5400,

  VIEW_MY_STORES: 5500,
}
